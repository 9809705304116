<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12>
        <v-card>
          <v-card-title class="title">
            <v-icon>layers</v-icon>
            Profit &amp; Loss Account
            <v-spacer></v-spacer>
            <v-btn
              outline
              small
              fab
              color="primary"
              @click="vertical = !vertical"
            >
              <v-icon v-if="!vertical" key="0">view_stream</v-icon>
              <v-icon v-if="vertical" style="transform: rotate(90deg)" key="1"
                >view_stream</v-icon
              >
            </v-btn>
          </v-card-title>
          <v-card-title class="title" style="margin-top: -25px">
            <v-flex xs12 sm3>
              <v-text-field
                label="From Date"
                v-model="trialbalance.filter_date.from_date"
                prepend-icon="event"
                mask="####-##-##"
                placeholder="YYYY-MM-DD"
                :height="25"
              />
            </v-flex>
            <v-flex xs12 sm3>
              <v-text-field
                label="To date"
                v-model="trialbalance.filter_date.to_date"
                prepend-icon="event"
                mask="####-##-##"
                placeholder="YYYY-MM-DD"
                :height="25"
              />
            </v-flex>
            <v-flex xs12 sm6 style="text-align: right">
              <v-btn
                fab
                small
                style="z-index: 1; background: blue"
                dark
                @click="downloadPl"
              >
                <v-icon dark>cloud_download</v-icon>
              </v-btn>
              <add-button
                :disabled="fetchingTrials"
                permission="section-create"
                @action="getTrials"
                :icon="icon"
                >Search
              </add-button>
            </v-flex>
          </v-card-title>
        </v-card>

        <table border="1" padding="0" v-if="vertical">
          <thead>
            <tr>
              <th style="text-align: left">Particulars</th>
              <th style="width: 50px">Ref</th>
              <th style="width: 150px">Amount (Rs.)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(t, k) in tree" :key="k">
              <td colspan="3">
                <pl-tree :tree-data="t" :root="k" type="tb1"></pl-tree>

                <!--profit-->
                <div v-if="plAmount > 0 && k === 0" class="pldiv">
                  <div style="width: 87%; padding-right: 2px">
                    <span class="pl"> <strong>Net Profit</strong></span>
                  </div>
                  <div style="width: 17%">
                    <span style=""
                      >{{ Math.abs(plAmount).numberFormat() }}
                    </span>
                  </div>
                </div>
                <div v-if="k === 0" class="pldiv">
                  <div style="width: 91%">
                    <span class="p"> <strong>Total</strong></span>
                  </div>
                  <div style="width: 12%">
                    <strong v-if="plAmount > 0">{{
                      (Math.abs(totalExpenses) + Math.abs(plAmount))
                        | fixAccount
                    }}</strong>
                    <strong v-else>{{ totalExpenses | fixAccount }}</strong>
                  </div>
                </div>

                <!--loss-->
                <div v-if="plAmount < 0 && k === 1" class="pldiv">
                  <div style="width: 87%; padding-right: 2px">
                    <span class="pl"> <strong>Net Loss</strong></span>
                  </div>
                  <div style="width: 17%">
                    <strong>{{ Math.abs(plAmount).numberFormat() }} </strong>
                  </div>
                </div>
                <div v-if="k === 1" class="pldiv">
                  <div style="width: 91%">
                    <span class="p"> <strong>Total</strong></span>
                  </div>
                  <div style="width: 12%">
                    <strong v-if="plAmount < 0">{{
                      (Math.abs(totalIncome) + Math.abs(plAmount)) | fixAccount
                    }}</strong>
                    <strong v-else>{{ totalIncome | fixAccount }}</strong>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="tree.length < 1">
              <td colspan="3" style="text-align: center">
                <strong>No records available.</strong>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <!--<tr>-->
            <!--<th colspan="2" style="text-align: left; padding: 0 5px;">-->
            <!--<span v-if="plAmount > 0">Net Profit</span> <span v-if="plAmount < 0">Net Loss </span>-->
            <!--</th>-->
            <!--<th style="text-align: right; padding: 0 5px;"><span v-if="tree && tree.length == 2">-->
            <!--&lt;!&ndash;{{ tree[0].balance - tree[1].balance | fixAccount }}&ndash;&gt;-->
            <!--&lt;!&ndash;{{(getClosing(tree[1]) - getClosing(tree[0])) | fixAccount}}&ndash;&gt;-->
            <!--{{Math.abs(plAmount).numberFormat()}}-->

            <!--</span>-->
            <!--</th>-->
            <!--</tr>-->
          </tfoot>
        </table>
        <table border="1" padding="0" style="" v-if="!vertical">
          <thead>
            <tr>
              <th style="text-align: left; width: calc(50% - 100px)">
                Particulars
              </th>
              <th style="width: 100px">Amount (Rs.)</th>
              <th style="text-align: left; width: 40%">Particulars</th>
              <th style="width: 100px">Amount (Rs.)</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" valign="top" style="position: relative">
                <pl-tree-h :tree-data="expenses" :root="0"></pl-tree-h>
                <div class="bar"></div>
              </td>
              <td colspan="2" valign="top" style="position: relative">
                <pl-tree-h :tree-data="income" :root="0"></pl-tree-h>
                <div class="bar"></div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th v-if="plAmount > 0">Net Profit</th>
              <!--<th v-else></th>-->
              <th v-if="plAmount > 0">
                {{ Math.abs(plAmount).numberFormat() }}
              </th>
              <th v-else colspan="2"></th>

              <th
                v-if="plAmount < 0"
                style="text-align: right; padding-right: 10px"
              >
                Net Loss
              </th>
              <th v-if="plAmount < 0" style="text-align: right">
                {{ Math.abs(plAmount).numberFormat() }}
              </th>
            </tr>
            <tr>
              <!--net Profit-->
              <th>Total</th>
              <th style="text-align: right">
                <strong v-if="expenses && plAmount > 0">{{
                  (Math.abs(totalExpenses) + Math.abs(plAmount)) | fixAccount
                }}</strong>
                <strong v-else>{{ totalExpenses | fixAccount }}</strong>
              </th>

              <!--net Loss-->
              <th>Total</th>
              <th style="text-align: right">
                <strong v-if="income && plAmount < 0">{{
                  (Math.abs(totalIncome) + Math.abs(plAmount)) | fixAccount
                }}</strong>
                <strong v-else>{{ totalIncome | fixAccount }}</strong>
              </th>
            </tr>
          </tfoot>
        </table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import Spinner from '@/components/Partials/Spinner'
// import Notification from '@/components/Partials/Notification'

import { mapState } from "vuex";

import PlTree from "@/components/Generic/PLTree/Tree";
import PlTreeH from "@/components/Generic/PLTree/TreeH";
import { passDataToExternalDomain } from "../../../../library/helpers";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();

export default {
  name: "pl-account",
  data: () => ({
    tree: [],
    vertical: true,
    income: [],
    expenses: [],
    plAmount: 0,
    fetchingTrials: false,
    icon: "search",
    frmDate: nd.format("YYYY-MM-DD"),
    toDate: nd.format("YYYY-MM-DD"),
  }),
  created() {
    this.getTrials();
  },
  computed: {
    ...mapState(["trialbalance"]),

    totalExpenses() {
      if (!["", undefined, null].includes(this.expenses.sum_dr)) {
        return (
          this.expenses.balance + this.expenses.sum_dr - this.expenses.sum_cr
        );
      }
      return 0;
    },
    totalIncome() {
      if (!["", undefined, null].includes(this.income.sum_cr)) {
        return this.income.balance + this.income.sum_dr - this.income.sum_cr;
      }
      return 0;
    },
  },
  watch: {
    "trialbalance.filter_date.from_date": function(val) {
      this.trialbalance.filter_date.from_date = this.trialbalance.filter_date.from_date.dateForm();
    },
    "trialbalance.filter_date.to_date": function(val) {
      this.trialbalance.filter_date.to_date = this.trialbalance.filter_date.to_date.dateForm();
    },
  },
  methods: {
    logClick(node) {},
    getTrials() {
      this.fetchingTrials = true;
      this.icon = "pending";
      this.$rest
        .get(
          "/api/trial-balance?type=profit-loss&frmDate=" +
            this.trialbalance.filter_date.from_date +
            "&toDate=" +
            this.trialbalance.filter_date.to_date
        )
        .then(({ data }) => {
          this.tree = data.filter((item) =>
            ["Income", "Expenses"].includes(item.name)
          );
          this.income = this.tree[1];
          this.expenses = this.tree[0];
          this.plAmount =
            Math.abs(
              this.income.balance + this.income.sum_dr - this.income.sum_cr
            ) -
            Math.abs(
              this.expenses.balance +
                this.expenses.sum_dr -
                this.expenses.sum_cr
            );
        })
        .finally(() => {
          this.fetchingTrials = false;
          this.icon = "search";
        });
    },
    downloadPl() {
      let reportType = "pl";
      if (this.status === "detail") {
        reportType = "pl_detail";
      }
      this.$rest.get("api/download/trial-balance").then((data) => {
        let url =
          data.data.data.download_url +
          "?type=pl&frmDate=" +
          this.trialbalance.filter_date.from_date +
          "&toDate=" +
          this.trialbalance.filter_date.to_date +
          "&reportType=" +
          reportType +
          "&hideZero=" +
          true;
        passDataToExternalDomain();
        console.log(url);
        setTimeout(() => {
          window.open(url);
        }, 300);
      });
    },
    getClosing(node) {
      return node.balance - node.sum_cr + node.sum_dr;
      // return node.balance
    },
  },
  components: { PlTree, PlTreeH },
  filters: {
    fixAccount(item) {
      if (!isNaN(item))
        return item >= 0 ? item.numberFormat() : `${(-item).numberFormat()}`;
    },
  },
};
</script>
<style lang="scss">
table {
  width: 100%;
  background: white;
  thead {
    th {
      padding: 5px;
    }
  }
  .p {
    margin-right: 116px !important;
    padding-left: 6px;
    padding-right: 9px;
    /*border-right: solid 1px;*/
  }
  .pl {
    margin-right: 76px !important;
    padding-left: 6px;
    padding-right: 9px;
    /*border-right: solid 1px;*/
  }
  .pldiv {
    text-align: right;
    border: solid 1px;
    display: flex;
  }
}
</style>
